import React, { useState } from 'react'
import {
  Theme,
  ThemeContext,
  handleChangeTheme,
} from './src/context/ThemeContext'

const Provider = props => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT)
  const [showNavigation, setShowNavigation] = useState<boolean>(false)
  const [showListView, setShowListView] = useState<boolean>(false)

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        changeTheme: (path: string) => handleChangeTheme(path, setTheme),
        showNavigation,
        toggleNavigation: () => setShowNavigation(!showNavigation),
        showListView,
        toggleListView: () => setShowListView(!showListView),
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
export default ({ element }) => <Provider>{element}</Provider>
