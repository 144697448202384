import { createContext, Dispatch } from 'react'

export enum Theme {
  DARK = 'theme-dark',
  LIGHT = 'theme-light',
  MEDIUM = 'theme-medium',
}

// change/toggle fns return React.setStateAction fns which return nothing (void in ts)
interface IThemeContext {
  theme: Theme
  changeTheme: (path: string) => void
  showNavigation: boolean
  toggleNavigation: () => void
  showListView: boolean
  toggleListView: () => void
}

export const handleChangeTheme = (path: string, setTheme: Dispatch<Theme>) => {
  // comment: hack to work with /projects/projectName, falls back to path if not real url like 'list-view'
  const pageName = path.split('/')[1] ?? path
  switch (pageName) {
    case 'terms-and-conditions':
    case 'media':
    case 'contact':
    case 'careers':
		case 'thank-you':
      return setTheme(Theme.LIGHT)
    case 'list-view':
    case 'projects':
      return setTheme(Theme.MEDIUM)
    case '/':
      return setTheme(Theme.DARK)
    default:
      return setTheme(Theme.DARK)
  }
}

// initialize as undefined, define IThemeContext object w/ values in provider.tsx using initial states
export const ThemeContext = createContext<IThemeContext | undefined>(undefined)
